<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-car-model-car :options="bodyTypes" @editForm="editForm"/>
    </div>
    <modal-car-model-car :categories="categories"  @refresh="refresh" />
    <modal-car-model-car-edit :categories="categories" @refresh="refresh" :id="id" />
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>
<script>
import ModalCarModelCar from '@/views/component/Modal/ModalModelCar/ModalCarModelCar.vue'
import ModalCarModelCarEdit from '@/views/component/Modal/ModalModelCar/ModalCarModelCarEdit.vue'
import tableCarModelCar from '@/views/component/Table/tableCarModelCar.vue'
export default {
  components: {
    ModalCarModelCar,
    ModalCarModelCarEdit,
    tableCarModelCar
  },
  data() {
    return {
      bodyTypes: [],
      categories: [],
      showPreloader: false,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Название' },
        { key: 'categoryCar', label: 'Категория' },
        { key: 'carBrand', label: 'Брэнд' },
        { key: 'classCar', label: 'Классы'},
        { key: 'is_active', label: 'Статус' },
        { key: 'date_time', label: 'Создан' },
      ],
      selectedBodyType: null,
      id: null,
    }
  },
  mounted(){
    this.openFilter()
    this.getCategories()
  },
  methods: {
    editForm(bodyType) {
      this.id = bodyType.id 
      setTimeout(() => {this.editModalCarModel() }, 0)
    },
    editModalCarModel() {
      this.$bvModal.show('EditModalCarModel')
    },
    getCategories() {
      this.$http.get('/car-settings/model-cars', {params: {is_active: true}})
      .then((res) => {
        this.categories = res.data
      })
    },
    openFilter(){
      let allRoutes = this.$store.state.draggableTab.tabs
      let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
      let arrayRoutes = []
      allRoutes.forEach(element => {
        arrayRoutes.push(element.path)
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true
        this.$http
        .get(`${this.$route.name}`, {params: param}).then(res => {
          this.bodyTypes = res.data
          this.showPreloader = false
          this.$store.commit('REFRESH_DATA', false)
        })
      }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
    },
    refresh(){
      this.showPreloader = true
      this.$http
      .get('car-settings/model-cars')
      .then(res => {
        this.bodyTypes = res.data
        this.showPreloader = false
        this.$store.commit('REFRESH_DATA', false)
      }).catch(err => {})
    },
    sendToParent(tableData){
      this.bodyTypes = tableData
    },
  },
  computed: {
    fetchingNewData(){
      return this.$store.state.refresh.fetchingNewData
    }
  },
  watch: {
    fetchingNewData(val){
      if (val){
        let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
        this.$http
        .get(`${this.$route.name}`, {params: params})
        .then(res =>{
          this.bodyTypes = res.data
          this.$store.commit('REFRESH_DATA', false)
        })
      }
    }
  }
}
</script>