<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить модель автомобиля" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="Название">Название <i style="color: red;">(Бренд + Модель)</i></label>
                    <b-input v-model="bodyType.name" placeholder="Название" />
                    <label for="">Модель</label>
                    <b-input v-model="bodyType.car_model" placeholder="Название" />
                    <label for="">Место от</label>
                    <b-input type="number" v-model.number="bodyType.car_seat_from" placeholder="Место от" />
                    <label for="">Место до</label>
                    <b-input type="number" v-model.number="bodyType.car_seat_before" placeholder="Место до" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <label for="">Категория</label>
                    <v-select v-model="bodyType.category_car_id" placeholder="Категория"
                        :reduce="options => options.id"
                        label="name"
                        :options="category"
                        @input="getCarData()">
                        <template #no-options>
                            <span>
                                Данные отсутствуют!
                            </span>
                        </template>
                    </v-select>
                    <label for="">Бренд</label>
                    <v-select v-model="bodyType.car_brand_id" placeholder="Бренд"
                        :disabled="condition" 
                        label="name" 
                        :reduce="option => option.id"
                        :options="car_brands">
                        <template #no-options>
                            <span>
                                Данные отсутствуют!
                            </span>
                        </template>
                    </v-select>
                    <label for="">Классы</label>
                    <v-select v-model="bodyType.class_car_id" placeholder="Классы"
                        :reduce="option => option.id"
                        :disabled="condition2"
                        label="name"
                        :options="car_classes">
                        <template #no-options>
                            <span>
                                Данные отсутствуют!
                            </span>
                        </template>
                    </v-select> 
                </b-col> 
            </b-row>
            <b-row class="mb-1">
                <b-col class="12">
                    <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                        {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
        vSelect,
    },
    props: ['categories'],
    data(){
        return {
            bodyType: {
                name: '',
                car_model: '',
                category_car_id: '',
                car_brand_id: '',
                class_car_id: '',
                is_active:  true,
                car_seat_from: null,
                car_seat_before: null
            },
            condition: true,
            condition2: true,
            submitButtonDisabled: false,
            categoriesSelectDisabled: false,
            freight_car: true,
            condition: true,
            condition2: true,
            class_car_id: null,
            class_car:[],
            category:[],
            car_brands:[],
            car_classes:[],
        }
    },
    mounted() {
        this.$http
            .get('directory/car-categories')
            .then(res => {
                this.category = res.data
            })
    },
    methods: {
        clearData(){
            this.bodyType = {
                name: null,
                car_model: null,
                car_brand_id: null,
                class_car_id: null,
                category_car_id: null,
                is_active:  true
            }
        },
        getCarData() {
            if (this.bodyType.category_car_id != null) {
                let car_categorie = {
                    category_car_id: this.bodyType.category_car_id
                }
                this.$http
                    .post('car-settings/model-cars/data', car_categorie)
                    .then(res => { 
                        this.car_brands = res.data.car_brands
                        this.car_classes = res.data.car_classes
                        this.condition = false
                        this.condition2 = false
                    })
            } else {
                this.condition = true
                this.condition2 = true
                this.bodyType.car_brand_id= ''
                this.bodyType.class_car_id = ''
            }
            if (this.bodyType.category_car_id == 3) {
                this.freight_car = false
            } else {
                this.freight_car = true
            }
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        createBodyType() {
            this.submitButtonDisabled = true
            this.$http.post('/car-settings/model-cars', this.bodyType)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип модель создан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },

    }
}
</script>